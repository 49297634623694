@import "whiteglass";

// enlarge max width of contents
.wrapper {
  max-width: 800px;
  max-width: -webkit-calc(800px);
}

@media print {
  pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
}

// from theme minima
.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: calc($spacing-unit / 2);
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

// toc support
.user-toc {
  background-color: #fff;
  margin: 5px 0;
  padding: 10px 30px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}

// no italic chinese characters
// a workaround for Chrome 96 and lower versions
@font-face {
  font-family: HanEm;
  font-style: italic;
  src: local("STFangsong"), local("FangSong"), local("FangSong_GB2312"),
    local("CWTEX\-F"), local("Kaiti SC"), local("STKaiti"),
    local("AR PL UKai CN"), local("KaiTi"), local("KaiTi_GB2312"),
    local("DFKai-SB"), local("LXGW WenKai"), local("PingFang SC"), local("Microsoft YaHei"),
    local("Source Han Sans CN");
}

em {
  font-family: Baskerville, "Times New Roman", "Liberation Serif", HanEm, serif;
  font-style: italic;
  font-synthesis: none;
}

.center {
  text-align: center;
}

// override title for preference of whiteglass over typo
.post-title {
  font-size: 42px !important;
  line-height: 1 !important;
}
